import { render, staticRenderFns } from "./esgManagementItemPop.vue?vue&type=template&id=14f04b10"
import script from "./esgManagementItemPop.vue?vue&type=script&lang=js"
export * from "./esgManagementItemPop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14f04b10')) {
      api.createRecord('14f04b10', component.options)
    } else {
      api.reload('14f04b10', component.options)
    }
    module.hot.accept("./esgManagementItemPop.vue?vue&type=template&id=14f04b10", function () {
      api.rerender('14f04b10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sai/esg/esgManagementItemPop.vue"
export default component.exports