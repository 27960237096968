<template>
  <div>
    <c-table
      ref="table"
      title="ESG(녹색경영) 항목 목록"
      rowKey="esgManagementItemId"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="gridHeight"
      selection="multiple"
      :editable="editable"
      :usePaging="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'esgManagementItemPop',
  props: {
    contentHeight: null,
  },
  data() {
    return {
      searchParam: {
        trainingTypeCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'esgManagementItemTypeName',
            field: 'esgManagementItemTypeName',
            label: '분류항목',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            required: true,
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
            style: 'width:500px',
          },
          {
            name: 'gmsIndexName',
            field: 'gmsIndexName',
            label: 'GMS Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'sasbIndexName',
            field: 'sasbIndexName',
            label: 'SASB Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
        ],
        data: [],
        height: '450px'
      },
      editable: true,
      isSave: false,
      listUrl: '',
      insertUrl: '',
      searchUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.esg.item.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '항목을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
